@import url("https://fonts.googleapis.com/css2?family=Play&display=swap");
html, body {
  height: 100%;
  font-family: 'Play', sans-serif;
  color: white;
  background-color: #27292b;
}

html h1, html h2, body h1, body h2 {
  font-family: 'Play', sans-serif;
}

.nav-top-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 10rem;
}

nav {
  background-color: black;
  font-family: 'Play', sans-serif;
  -webkit-box-shadow: 0 0 10px black;
          box-shadow: 0 0 10px black;
  font-weight: bold;
}

nav .hamburger-menu {
  display: none;
}

nav .hidden {
  display: none;
}

nav a {
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  color: black;
}

nav .nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
  margin: 0 2rem;
}

nav .nav-list .nav-list-item, nav .nav-list .nav-list-trok, nav .nav-list .nav-list-hide {
  list-style: none;
  font-weight: bold;
  position: relative;
  padding: 1.5rem 1rem;
  background-color: #d0fc31;
  -webkit-box-shadow: 2px 2px 3px 2px #d0fc31;
          box-shadow: 2px 2px 3px 2px #d0fc31;
  cursor: pointer;
}

nav .nav-list .nav-list-item .nav-list-dropdown, nav .nav-list .nav-list-trok .nav-list-dropdown, nav .nav-list .nav-list-hide .nav-list-dropdown {
  position: absolute;
  background-color: black;
  top: 5rem;
  left: -1rem;
  width: 12rem;
  padding: .5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: .5rem;
  visibility: visible;
}

nav .nav-list .nav-list-item .nav-list-dropdown li, nav .nav-list .nav-list-trok .nav-list-dropdown li, nav .nav-list .nav-list-hide .nav-list-dropdown li {
  list-style: none;
  padding: 0.5rem 1rem;
}

button {
  background: #d0fc31;
  text-decoration: none;
  border-radius: 2px;
  padding: 1em 3em 1em 3em;
  border-style: none;
  color: black;
  -webkit-box-shadow: 1px 1px 1px 1px;
          box-shadow: 1px 1px 1px 1px;
  cursor: pointer;
}

button:hover {
  -webkit-transition: all .5s;
  transition: all .5s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.container .logobar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5rem;
}

.container {
  margin: 3rem 5rem 0 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.heroimg img {
  max-width: 100%;
  height: auto;
}

hr {
  margin-top: 5em;
  width: 50%;
  color: white;
  size: 50px;
  -webkit-box-shadow: 0 0 10px #27292b;
          box-shadow: 0 0 10px #27292b;
}

.container .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.container .info .info-text {
  margin-top: 5em;
  width: 30rem;
}

.container .info .info-text-left {
  text-align: left;
}

.container .info .info-text-right {
  text-align: right;
}

.container .info .info-img {
  margin-top: 5rem;
}

.container .info .left-img {
  margin-right: 2rem;
}

.container .info .right-img {
  margin-left: 2rem;
}

.container button a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.companies {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.companies .company-text {
  margin-top: 3em;
  text-align: center;
  font-size: 16px;
  width: 50vw;
}

.container-company {
  margin-right: 5rem;
  margin-left: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
}

.container-company .company-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 5rem;
  border-radius: 5px;
  border-style: solid;
  border-color: #d0fc31;
  -webkit-box-shadow: 0 0 20px #27292b;
          box-shadow: 0 0 20px #27292b;
  width: 30rem;
}

.container-company .company-cards .companycard-title {
  margin-top: 2rem;
  height: 3em;
}

.container-company .company-cards .description {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
  height: 20em;
}

.container-company .company-cards .cta-button {
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.container-company .company-cards .cta-button a button {
  font-weight: bold;
  font-family: 'Play', sans-serif;
}

.container .board-cards .cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.guild-principles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.guild-principles .guild-list p {
  text-align: center;
}

.guild-principles .guild-list ul li {
  list-style-type: none;
  text-align: left;
}

.open-hours {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
}

.open-hours .times {
  margin-left: 1em;
}

.card {
  width: 250px;
  color: black;
  padding: 1em;
  border-radius: 4px;
  margin: 1em;
  background: #d0fc31;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all .5s;
  transition: all .5s;
  -webkit-box-shadow: 0 0 5px grey;
          box-shadow: 0 0 5px grey;
  border-color: black;
}

.card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.card .card-title {
  height: 3em;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.card .card-name-email {
  margin-top: 1.5em;
}

.card #card-name {
  font-size: 18px;
  font-weight: bold;
}

.footer {
  margin-top: 10em;
  background-color: black;
  bottom: 0;
  position: relative;
  color: white;
}

.footer .footer-content .footer-address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.footer .footer-content .social-logos {
  padding-left: 10rem;
}

.footer .footer-content .social-logos img {
  margin: 1em;
  width: 50px;
  height: 50px;
}

.container .contact-info .contact-office, .container .contact-info .contact-invoice {
  border-radius: 5px;
  border-style: solid;
  border-color: #d0fc31;
  -webkit-box-shadow: 0 0 20px #27292b;
          box-shadow: 0 0 20px #27292b;
  margin-top: 2rem;
  padding: 1rem;
}

.form {
  margin-top: 3rem;
  background-color: #27292b;
  border-radius: 5px;
  border-style: solid;
  border-color: #d0fc31;
  -webkit-box-shadow: 0 0 20px #27292b;
          box-shadow: 0 0 20px #27292b;
}

.form .form-fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 3em;
}

.form .form-fields #input-name, .form .form-fields #input-subject, .form .form-fields #input-email, .form .form-fields #input-message, .form .form-fields #contact-submit {
  padding: .5rem;
  margin-top: 1rem;
  border-radius: 5px;
}

.form .form-fields #input-message {
  width: 443px;
  height: 132px;
}

.form .form-fields button {
  width: 50%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.fb-page, .fb_iframe_widget {
  margin-top: 4em;
  width: 500px;
  height: 500px;
}

@media only screen and (max-width: 1350px) {
  nav {
    background-color: none;
    font-family: 'Open Sans', sans-serif;
    -webkit-box-shadow: 0 0 10px black;
            box-shadow: 0 0 10px black;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  nav a {
    text-decoration: none;
    color: white;
  }
  nav .hamburger-menu {
    display: block;
  }
  nav .hamburger-menu img {
    display: block;
  }
  nav .nav-list {
    margin-right: 3em;
    background-color: black;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: none;
    margin: 0;
    padding: 0;
  }
  nav .nav-list .nav-list-hide {
    display: none;
  }
  nav .nav-list .nav-top-logo {
    display: none;
  }
  nav .nav-list .nav-list-item, nav .nav-list .nav-list-trok {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    list-style: none;
    font-weight: bold;
    padding-top: .15rem;
    background-color: black;
    color: white;
    -webkit-box-shadow: none;
            box-shadow: none;
    gap: 0;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  nav .nav-list .nav-list-item .nav-list-dropdown, nav .nav-list .nav-list-trok .nav-list-dropdown {
    position: relative;
    text-align: left;
    background-color: black;
    top: 1rem;
    left: -1rem;
    width: 6rem;
    margin-top: .2rem;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0;
    visibility: visible;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  nav .nav-list .nav-list-item .nav-list-dropdown li, nav .nav-list .nav-list-trok .nav-list-dropdown li {
    list-style: none;
  }
  .heroimg img {
    max-width: 100%;
    height: auto;
  }
  hr {
    margin-top: 5em;
    width: 100%;
    color: white;
    size: 50px;
    -webkit-box-shadow: 0 0 10px #27292b;
            box-shadow: 0 0 10px #27292b;
  }
  .container .info-img {
    display: none;
  }
  .container .info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .container .info .info-text {
    margin-top: 5em;
    width: 20rem;
  }
  .container .info .info-text-left {
    text-align: left;
  }
  .container .info .info-text-right {
    text-align: left;
  }
  .container .info .info-img {
    margin-top: 5rem;
  }
  .container .info .left-img {
    margin-right: 2rem;
  }
  .container .info .right-img {
    margin-left: 2rem;
  }
  .container button a {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }
  .container .board-cards .cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .companies {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .companies .company-text {
    margin-top: 3em;
    text-align: center;
    font-size: 16px;
    width: 80vw;
  }
  .container-company {
    margin-right: 0;
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .container-company .company-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 5rem;
    border-radius: 5px;
    border-style: solid;
    border-color: #d0fc31;
    -webkit-box-shadow: 0 0 20px #27292b;
            box-shadow: 0 0 20px #27292b;
    width: 20rem;
  }
  .container-company .company-cards .companycard-title {
    margin-top: 2rem;
    height: 3em;
  }
  .container-company .company-cards .description {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    margin-top: 2rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .container-company .company-cards .cta-button {
    margin-top: 1rem;
    padding-bottom: 2rem;
  }
  .form {
    margin-top: 3rem;
    background-color: #27292b;
    border-radius: 5px;
    border-style: solid;
    border-color: #d0fc31;
    -webkit-box-shadow: 0 0 20px #27292b;
            box-shadow: 0 0 20px #27292b;
  }
  .form .form-fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 3em;
  }
  .form .form-fields #input-name, .form .form-fields #input-subject, .form .form-fields #input-email, .form .form-fields #input-message, .form .form-fields #contact-submit {
    padding: .5rem;
    margin-top: 1rem;
    border-radius: 5px;
  }
  .form .form-fields #input-message {
    width: 225px;
    height: 132px;
  }
  .form .form-fields button {
    width: 50%;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .footer {
    margin-top: 10em;
    background-color: black;
    bottom: 0;
    position: relative;
    color: white;
  }
  .footer .footer-content .footer-address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer .footer-content .social-logos {
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .footer .footer-content .social-logos a {
    height: 40px;
  }
  .footer .footer-content .social-logos img {
    margin: 1em;
    width: 30px;
    height: 30px;
  }
}
