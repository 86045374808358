$button-color: hsl(73, 97%, 59%);
$main-color: black;
$main-color2: hsl(73, 97%, 39%);
$footer-color: #27292b;

$bg-color-lg: rgb(164, 162, 162);
$nav-color: black;


@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');



html, body {

    height: 100%;
    font-family: 'Play', sans-serif;
    color: white;
    background-color: #27292b;

    h1, h2 {
            
        font-family: 'Play', sans-serif;
        
    }

    
  }



//  Navbar css

.nav-top-logo {
    display: flex;
    justify-content: center;
    margin-right: 10rem;
    
 }


nav {
    
    background-color: $main-color;
    font-family: 'Play', sans-serif;
    box-shadow: 0 0 10px black;
    font-weight: bold;


    .hamburger-menu {
        display: none;
    }

    .hidden {
        display: none;
    }

    
    
    a {
        text-decoration: none;
        font-weight: bold;
        font-size: 20px;
        color: black;
    }

    .nav-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin: 0 2rem;

        


       

        .nav-list-item, .nav-list-trok, .nav-list-hide {
            list-style: none;
            font-weight: bold;
            position: relative;
            padding: 1.5rem 1rem;
            background-color: $button-color;
            box-shadow: 2px 2px 3px 2px $button-color;
            cursor: pointer;
            

            

            
            
            
            

            .nav-list-dropdown {
                position: absolute;
                background-color: black;
                top: 5rem;
                left: -1rem;
                width: 12rem;
                padding: .5rem;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                visibility: visible;
                //box-shadow: 0 0 2px $main-color2;

                li {
                    list-style: none;
                    padding: 0.5rem 1rem;
                }
            }
        }
    }
}


//  Button css
button {
    background: $button-color;
    text-decoration: none;
    border-radius: 2px;
    padding: 1em 3em 1em 3em;
    border-style: none;
    color: black;
    box-shadow: 1px 1px 1px 1px;
    cursor: pointer;

    &:hover {
        transition: all .5s;
        transform: scale(1.1)
    }

}

// Logobar

.container {
    .logobar {
        display: flex;
        margin-top: 5rem;
        
    }
}

//  Container in every page
.container {
    margin: 3rem 5rem 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

}


// Home page

.heroimg {

    img {
        max-width: 100%;
        height: auto;
    }
    
}

hr {
    margin-top: 5em;
    width: 50%;
    color: white;
    size: 50px;
    box-shadow: 0 0 10px $footer-color;
    
}

.container {

    .info {
        display: flex;
        flex-direction: row;

        .info-text {
            
            margin-top: 5em;
            width: 30rem;
        }

        .info-text-left {
            text-align: left;
        }
        .info-text-right {
            text-align: right;
        }

        .info-img {
            margin-top: 5rem;
        }

        .left-img {
            margin-right: 2rem;
        }
        .right-img {
            margin-left: 2rem;
        }
    }

    button a{
        text-decoration: none;
        color: black;
        font-weight: bold;
    }
}


// Companies

.companies {

    display: flex;
    justify-content: center;

.company-text {
    margin-top: 3em;
    text-align: center;
    font-size: 16px;
    width: 50vw;
}
}

.container-company {
    margin-right: 5rem;
    margin-left: 5rem;
    display: flex;
    justify-content: space-between;
    text-align: center;
    
    .company-cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5rem;
        border-radius: 5px;
        border-style: solid;
        border-color: $button-color;
        box-shadow: 0 0 20px $footer-color;
        width: 30rem;

        .companycard-title {
            margin-top: 2rem;
            height: 3em;
        }

        .description {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: 2rem;
            height: 20em;
        }

        .cta-button {
            margin-top: 2rem;
            padding-bottom: 2rem;
            a button{
                font-weight: bold;
                font-family: 'Play', sans-serif;
                
            }
            
        }

    }
}

    

    
//  Board-page css for card container
    .container .board-cards {

        
        
        .cards {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        
    }

    .guild-principles{
        display: flex;
        justify-content: center;
        .guild-list {
            p {
                text-align: center;
                
            }
        
        ul {
            li {
                list-style-type: none;
                text-align: left;
                
                
            }
        }
        
    }
        
    }

    .open-hours{
        
        display: flex;
        text-align: left;

        .times {
            margin-left: 1em;
        }
        
    }
    


    






//  Card-component
.card {
    width: 250px;
    color:black;
    padding: 1em;
    border-radius: 4px;
    margin: 1em;
    background: $button-color;
    display: flex;
    flex-direction: column;
    transition: all .5s;
    box-shadow: 0 0 5px grey;
    border-color: black;
    
    &:hover {
        transform: scale(1.05);
    }

    .card-title {
        height: 3em;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        
        
    }

    .card-name-email {
        
        margin-top: 1.5em;
        
    }

    #card-name {
        font-size: 18px;
        font-weight: bold;
    }
}

// Footer

.footer { 
    margin-top: 10em;
    background-color: $main-color;
    bottom: 0;
    position: relative;
    color: white;
    
    

    .footer-content {
        

        .footer-address {
            display: flex;
            justify-content: center;
        }

        .social-logos {
            padding-left: 10rem;
            img {
                margin: 1em;
                width: 50px;
                height: 50px;
            }
        }        
    }
}
    

// Contact

.container {
    .contact-info {
        .contact-office, .contact-invoice {
            border-radius: 5px;
            border-style: solid;
            border-color: $button-color;
            box-shadow: 0 0 20px $footer-color;
            margin-top: 2rem;
            padding: 1rem;
        }
    }
}
//Yhteydenottolomake

.form {
    margin-top: 3rem;
    background-color: $footer-color;
    border-radius: 5px;
    border-style: solid;
    border-color: $button-color;
    box-shadow: 0 0 20px $footer-color;
    
    
    .form-fields {
        display: flex;
        flex-direction: column;
        padding: 3em;
        
        

        #input-name ,#input-subject, #input-email, #input-message, #contact-submit{
            padding: .5rem;
            margin-top: 1rem;
            border-radius: 5px;
            
            
        }

        #input-message {
            width: 443px;
            height: 132px;
        }

        button {
            width: 50%;
            align-self: center;
        }

        

        

       
        
        
    }
}



// Facebook-upotus
.fb-page, .fb_iframe_widget {
    margin-top: 4em;
    width: 500px;
    height: 500px;
}



@media only screen and (max-width: 1350px) {


    
    
// Mobile navigation

    nav {   
        background-color: none;
        font-family: 'Open Sans', sans-serif;
        box-shadow: 0 0 10px black;
        display: flex;
        justify-content: center;
        
        
        
        a {
            text-decoration: none;
            color: white;
            
            
        }

        .hamburger-menu {
            
            //margin-left: 6rem;
            display: block;
            img {
                
                display: block;
            }
            
        }
    
        .nav-list {
            margin-right: 3em;
            background-color: black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: none;
            margin: 0;
            padding: 0;
            //width: 100%;
            

            .nav-list-hide {
                display: none;
            }

            .nav-top-logo {
                display: none;
                
             }


    
            .nav-list-item, .nav-list-trok {
                display: flex;
                flex-direction: column;
                list-style: none;
                font-weight: bold;
                padding-top: .15rem;
                background-color: $main-color;
                color: white;
                box-shadow: none;
                gap: 0;
                cursor: pointer;
                justify-content: center;

                
                
                
    
                
    
                
                
            
                
    
                .nav-list-dropdown {
                    position: relative;
                    text-align: left;
                    background-color: $main-color;
                    top: 1rem;
                    left: -1rem;
                    width: 6rem;
                    margin-top: .2rem;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    visibility: visible;
                    box-shadow: none;
    
                    li {
                        list-style: none;
                        //padding: 0.5rem 1rem;
                        
                    }
                }
            }
        }
    }




// Mobile home page


.heroimg {

    img {
        max-width: 100%;
        height: auto;
    }
    
}

hr {
    margin-top: 5em;
    width: 100%;
    color: white;
    size: 50px;
    box-shadow: 0 0 10px $footer-color;
    
}

.container {

    .info-img {
        display: none;
    }

    .info {
        display: flex;
        flex-direction: column;

        .info-text {
            
            margin-top: 5em;
            width: 20rem;
        }

        .info-text-left {
            text-align: left;
        }
        .info-text-right {
            text-align: left;
        }

        .info-img {
            margin-top: 5rem;
        }

        .left-img {
            margin-right: 2rem;
        }
        .right-img {
            margin-left: 2rem;
        }
    }

    button a{
        text-decoration: none;
        color: black;
        font-weight: bold;
    }
}

// Board mobile

.container .board-cards {

        
        
    .cards {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
}


// Company mobile

.companies {

    display: flex;
    justify-content: center;

.company-text {
    margin-top: 3em;
    text-align: center;
    font-size: 16px;
    width: 80vw;
}
}


.container-company {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    
    .company-cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5rem;
        border-radius: 5px;
        border-style: solid;
        border-color: $button-color;
        box-shadow: 0 0 20px $footer-color;
        width: 20rem;

        .companycard-title {
            margin-top: 2rem;
            height: 3em;
        }

        .description {
            padding-left: 0.2rem;
            padding-right: 0.2rem;
            margin-top: 2rem;
            height: fit-content;
        }

        .cta-button {
            margin-top: 1rem;
            padding-bottom: 2rem;
        }

    }
}

//Contact-form mobile

.form {
    margin-top: 3rem;
    background-color: $footer-color;
    border-radius: 5px;
    border-style: solid;
    border-color: $button-color;
    box-shadow: 0 0 20px $footer-color;
    
    
    .form-fields {
        display: flex;
        flex-direction: column;
        padding: 3em;
        
        

        #input-name ,#input-subject, #input-email, #input-message, #contact-submit{
            padding: .5rem;
            margin-top: 1rem;
            border-radius: 5px;
            
            
        }

        #input-message {
            width: 225px;
            height: 132px;
        }

        button {
            width: 50%;
            align-self: center;
        }

        

        

       
        
        
    }
}

// Footer mobile

.footer { 
    margin-top: 10em;
    background-color: $main-color;
    bottom: 0;
    position: relative;
    color: white;
    
    

    .footer-content {
        

        .footer-address {
            display: flex;
            justify-content: center;
        }

        .social-logos {
            padding-left: 0;
            display: flex;
            flex-direction: row;

            a {
                height: 40px;
            }
            img {
                margin: 1em;
                width: 30px;
                height: 30px;
            }
        }        
    }
}







    

}

